import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import LoaderWrapper from '../components/Loader/LoaderWrapper';
import enMessages from '../lang/en.json';
import frMessages from '../lang/fr.json';
import { useAppContext } from './AppContext';
import polyfill from '../utils/polyfill';

type TranslationProviderProps = {
    disableTranslationLoading?: boolean;
};

const messages: Record<string, any> = { en: enMessages, fr: frMessages };

const TranslationProvider: React.FC<TranslationProviderProps> = ({ children, disableTranslationLoading = false }) => {
    const { site, locale } = useAppContext();
    const [loadedTranslation, setLoadedTranslations] = useState<Record<string, any>>(
        disableTranslationLoading ? {} : messages[locale]
    );

    useEffect(() => {
        setLoadedTranslations(messages[locale]);
        const getPolyfill = async () => await polyfill(locale);
        getPolyfill();
    }, [locale]);

    useEffect(() => {
        let mounted = true;
        if (site && site.id && !disableTranslationLoading && mounted) {
            const translationUrl = `${document.location.origin}/locales/checkout/${locale}.json`;
            fetch(translationUrl)
                .then(response => response.json())
                .then(translationData => {
                    if (mounted) {
                        setLoadedTranslations({ ...messages[locale], ...translationData });
                    }
                })
                .catch(e => {
                    console.warn("Can't load translation", e);
                });
        }

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [site?.id, locale, disableTranslationLoading]);

    useEffect(() => {
        setLoadedTranslations(disableTranslationLoading ? {} : messages[locale]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale]);

    return (
        <LoaderWrapper isLoading={!site?.id} loaderProps={{ fullScreen: true, large: true }}>
            <IntlProvider locale={locale} messages={loadedTranslation}>
                {children}
            </IntlProvider>
        </LoaderWrapper>
    );
};

export default TranslationProvider;
